// import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { getExpressionTypes } from '../utils/FeatureUtils';
import { FormHelperText } from "@mui/material";
import GlobalContext from "../context/optionContext";
import { sendNewEntityRequest } from "../utils/FetchUtils";

const expressionTypes = getExpressionTypes();
const newPlasmidForm = {
    name: '',
    parent: '',
    expressionType: 'Yeast Expression',
    rationale: '',
    submitterEmail: '',
    sequence: '',
    supplier: '',
}
const newFeatureForm = {
    name: '',
    type: '',
    rationale: '',
    submitterEmail: '',
    sequence: '',
}

const newREForm = {
    name: '',
    cutSequence: '',
    submitterEmail: '',
    rationale: '',
}

const RequestPage = () => {
    const { theme, language } = React.useContext(GlobalContext);

    // const [formData, setFormData] = useState({ newPlasmidForm, newFeatureForm, newREForm });
    const [plasmidData, setPlasmidData] = useState(newPlasmidForm);
    const [featureData, setFeatureData] = useState(newFeatureForm);
    const [enzymeData, setEnzymeData] = useState(newREForm);
    const [requestType, setRequestType] = useState('');
    const [hostOrganism, setHostOrganism] = useState('');
    const handleDataChange = (setter, field) => (event) => {
        setter((prevState) => ({
            ...prevState,
            [field]: event.target.value,
        }));
    };

    useEffect(() => {
        console.log('plasmidData', plasmidData);
        console.log('featureData', featureData);
        console.log('enzymeData', enzymeData);
    }, [plasmidData, featureData, enzymeData]);


    const handleSubmit = async (event, plasmidData, featureData, enzymeData) => {
        event.preventDefault();
        // Handle form submission logic here

        // Collect form data
        const formData = {
            requestType,
            plasmidData,
            featureData,
            enzymeData
        };

        // Send the data to the server or API
        // You can use fetch or any other library to make the request
        console.log('Submitting form data:', formData);
        let res = await sendNewEntityRequest(formData).then((res) => {
            // let resJson = res.json();
            console.log('resJson', res);
        })

    };

    return (
        <Layout>
            <Seo title="Request" />
            <h1>Request</h1>
            <div>
                COMING SOON...

            </div>

        </Layout >
    )
}

export default RequestPage
